.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1d595e;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  justify-content: space-around;
  background-color: #143109; /* Dark background */
  padding: 20px;
  text-align: center;
  align-items: center;
  color: #f0f7f4; /* Light text color */
}

.App-header h1 {
  margin: 0;
  font-size: 2rem;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  background-color: #143109; /* Dark green background */
  color: #f0f7f4; /* Light text color */
  text-align: center;
  padding: 10px;
  margin-top: auto;
}

.logo {
    border: 2px solid #fff3;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    height: auto;
    width: 100px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.credits {
  text-align: left; /* Align text to the left */
  padding: 20px;
}

.credits h2 {
  margin: 0 0 10px;
  font-size: 1.5em;
}

.credits p {
  margin: 0;
  font-size: 1em;
}

.credits ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  display: flex; /* Use flexbox for horizontal layout */
  flex-wrap: wrap; /* Allow wrapping if needed */
  justify-content: flex-start; /* Align items to the left */
}

.credits li {
  margin: 0 10px; /* Add horizontal spacing between items */
  font-size: 1.2em;
  color: #fff; /* Adjust color as needed */
}

.credits a {
  color: #ffcc00; /* Bright yellow color for better contrast */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make the text bold */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.credits a:hover {
  color: #ff9900; /* Slightly darker yellow on hover */
}

body {
  font-family: "Dancing Script", cursive; /* Apply Dancing Script font */
}
